import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/tmp/node_modules/@nib/gatsby-theme-mesh-docs/src/templates/Content.js";
import { ColumnDef, Row, Column, getFacetedRowModel, flexRender, getCoreRowModel, getSortedRowModel, SortingState, getFilteredRowModel, getPaginationRowModel, useReactTable, getExpandedRowModel, ExpandedState, getFacetedUniqueValues, getFacetedMinMaxValues, FilterFn } from '@tanstack/react-table';
import { TableWrapper, Table, TableHead, TableHeadRow, TableHeading, TableBody, TableExpandRow, TableExpandHeading, TableRow, TableData, Pagination, IndeterminateCheckbox, SimpleTable } from '@nib/table';
import styled from 'styled-components';
import { SearchSystemIcon } from '@nib/icons';
import { Box, p, pr, py, pl } from '@nib/layout';
import Alert from '@nib-components/alert';
import Copy from '@nib-components/copy';
import { FormControl } from '@nib-components/form-control';
import Link from '@nib-components/link';
import Textbox from '@nib-components/textbox';
import { RankingInfo, rankItem } from '@tanstack/match-sorter-utils';
import { equal } from 'assert';
import { css } from 'styled-components';
import { copyColor, copyFontFamily, copyFontSizeSmall, formBorderColor, formBorderColorValid, formBorderColorInvalid, formInputBackgroundColor, focusOutlineColor, focusOutlineStyle, focusOutlineWidth, focusOutlineOffset } from '@nib-components/theme';
import { BasicTable } from '../../src/components/table/SimpleTable';
import { SortingTable } from '../../src/components/table/SortingTable';
import { RowSelectionTable } from '../../src/components/table/RowSelectionTable';
import { PaginationTable } from '../../src/components/table/PaginationTable';
import { FilterTable } from '../../src/components/table/FilterTable';
import { ExpandingRowTable } from '../../src/components/table/ExpandingRowTable';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <Alert type="info" title="Experimental but encouraged!" mdxType="Alert">
  <Copy measure={false} mdxType="Copy">
    Our updated table component has been flagged as "experimental" due to the recent move to a "semi-headless" approach. Its use is encouraged, as is any feedback you can provide about the way the
    component works, the API design or any other concerns.
  </Copy>
    </Alert>
    <p>{`We are leveraging the open source package `}<a parentName="p" {...{
        "href": "https://tanstack.com/table/v8"
      }}><inlineCode parentName="a">{`react-table`}</inlineCode></a>{` which does much of the heavy lifting for us. `}<inlineCode parentName="p">{`react-table`}</inlineCode>{` is a "headless UI" package which means it exports no components, only hooks. You must bring your own markup to use `}<inlineCode parentName="p">{`react-table`}</inlineCode>{`. This may sound like additional work, and it is, but it means that it is extremely powerful and flexible, supporting a vast array of dynamic table functionality:`}</p>
    <ul>
      <li parentName="ul">{`Sorting`}</li>
      <li parentName="ul">{`Filtering`}</li>
      <li parentName="ul">{`Pagination`}</li>
      <li parentName="ul">{`Row Expansion`}</li>
      <li parentName="ul">{`Row Selection`}</li>
      <li parentName="ul"><em parentName="li">{`...and much more!`}</em></li>
    </ul>
    <p>{`React-table supports almost any kind of data table you can imagine. Take a look at their `}<a parentName="p" {...{
        "href": "https://tanstack.com/table/v8/docs/examples/react/"
      }}>{`examples`}</a>{` to see.`}</p>
    <p>{`Our Mesh table provides the UI pieces for you to put together the markup for your `}<inlineCode parentName="p">{`react-table`}</inlineCode>{`, as well as some small abstractions where it makes sense.`}</p>
    <p>{`As such, you will need to install this package, but also the `}<inlineCode parentName="p">{`@tanstack/react-table`}</inlineCode>{` directly in your solution.`}</p>
    <h2 {...{
      "id": "installation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#installation",
        "aria-label": "installation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Installation`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install @nib/table @tanstack/react-table
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`Note that we are also installing `}<inlineCode parentName="p">{`@tanstack/react-table`}</inlineCode>{` separately, as it is not included as a dependency.
Note: You will also need to install the peerDependencies `}<a parentName="p" {...{
          "href": "/components/icons/"
        }}>{`@nib/icons`}</a>{` and `}<a parentName="p" {...{
          "href": "/components/theme/"
        }}>{`@nib-components/theme`}</a>{`.`}</p>
    </blockquote>
    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#usage",
        "aria-label": "usage permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Usage`}</h2>
    <p>{`We export the following components for use with `}<inlineCode parentName="p">{`react-table`}</inlineCode>{`:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Component`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`TableWrapper`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`optional outer wrapper to control horizontal overflow`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`TableCaption`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`optional caption above or below the table`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`Table`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`the `}<inlineCode parentName="td">{`table`}</inlineCode>{` element`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`TableHead`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`the `}<inlineCode parentName="td">{`thead`}</inlineCode>{` element`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`TableHeadRow`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`the `}<inlineCode parentName="td">{`tr`}</inlineCode>{` element for use within the `}<inlineCode parentName="td">{`TableHead`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`TableHeading`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`a `}<inlineCode parentName="td">{`th`}</inlineCode>{` element, with prebuilt functionality to support sorting`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`Th`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`a static `}<inlineCode parentName="td">{`th`}</inlineCode>{` element`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`TableBody`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`the `}<inlineCode parentName="td">{`tbody`}</inlineCode>{` element`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`TableRow`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`the `}<inlineCode parentName="td">{`tr`}</inlineCode>{` element`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`TableData`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`the `}<inlineCode parentName="td">{`td`}</inlineCode>{` element`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`TableExpandHeading`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`an empty `}<inlineCode parentName="td">{`th`}</inlineCode>{` element for use in expanding tables`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`TableExpandRow`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`the `}<inlineCode parentName="td">{`tr`}</inlineCode>{` element for use in expanding rows`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`TableExpandData`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`the `}<inlineCode parentName="td">{`td`}</inlineCode>{` element containing the button and chevron for expanding tables`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`IndeterminateCheckbox`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`a checkbox for use with a row selecting table`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`Pagination`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`A collection of pagination controls`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`SearchBox`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`A simple search form for a global filter`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`SimpleTable`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><em parentName="td">{`(deprecated)`}</em></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "basic",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#basic",
        "aria-label": "basic permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Basic`}</h3>
    <h4 {...{
      "id": "static",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#static",
        "aria-label": "static permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Static`}</h4>
    <p>{`For static tables our table elements (`}<inlineCode parentName="p">{`Table`}</inlineCode>{`, `}<inlineCode parentName="p">{`TableHead`}</inlineCode>{`, `}<inlineCode parentName="p">{`TableBody`}</inlineCode>{`, `}<inlineCode parentName="p">{`TableRow`}</inlineCode>{`, `}<inlineCode parentName="p">{`TableData`}</inlineCode>{`, etc.) can be used directly in your markup:`}</p>
    {/* prettier-ignore */}
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import {Table, TableHead, TableHeadRow, TableHeading, TableBody, TableRow, TableData} from '@nib/table';

<Table>
  <TableHead>
    <TableHeadRow>
      <TableHeading>Column 1</TableHeading>
      <TableHeading>Column 2</TableHeading>
    </TableHeadRow>
  </TableHead>
  <TableBody>
    <TableRow>
      <TableData>Sample data</TableData>
      <TableData>Sample data</TableData>
    </TableRow>
  </TableBody>
</Table>;
`}</code></pre>
    <h4 {...{
      "id": "dynamic",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#dynamic",
        "aria-label": "dynamic permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Dynamic`}</h4>
    <p>{`For more dynamic data, the Mesh Table components are to be used in conjunction with the `}<inlineCode parentName="p">{`useReactTable`}</inlineCode>{` hook from `}<inlineCode parentName="p">{`@tanstack/react-table`}</inlineCode>{`.`}</p>
    <p>{`At this point it is a good idea to familiarise yourself with `}<a parentName="p" {...{
        "href": "https://tanstack.com/table/v8/docs/guide/column-defs"
      }}>{`Column Defs`}</a>{`. As the docs state `}<em parentName="p">{`"Column defs are the single most important part of building a table."`}</em>{` and are responsible for the building the underlying data model that will be used for everything including sorting, filtering, grouping, etc.`}</p>
    <BasicTable mdxType="BasicTable" />
    {/* prettier-ignore */}
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React from 'react';
import {flexRender, getCoreRowModel, createColumnHelper, useReactTable} from '@tanstack/react-table';
import {TableWrapper, TableCaption, Table, TableHead, TableHeadRow, TableHeading, TableBody, TableRow, TableData} from '@nib/table';

const validRowHeightValues = ['relaxed', 'regular', 'condensed'] as const;
type rowHeightValues = (typeof validRowHeightValues)[number];

const validCaptionSideValues = ['top', 'bottom'] as const;
type captionSideValues = (typeof validCaptionSideValues)[number];

export interface BasicTableProps {
  caption?: string;
  captionSide?: captionSideValues;
  rowHeight?: rowHeightValues;
  height?: string;
  maxHeight?: string;
  rowHover?: boolean;
  stripedRows?: boolean;
  equalColumns?: boolean;
  fixedHeader?: boolean;
  fixedColumn?: boolean;
  [key: string]: unknown;
}

type Row = {
  column1: string;
  column2: string;
  column3: string;
  column4: string;
  column5: string;
  column6: string;
}

export const BasicTable: React.FC<BasicTableProps> = ({
  caption,
  captionSide,
  stripedRows,
  rowHover,
  height,
  maxHeight,
  equalColumns,
  rowHeight,
  fixedHeader,
  fixedColumn,
  ...otherProps
}) => {
  const columnHelper = createColumnHelper<Row>();
  const columns = [
    columnHelper.accessor('column1', {
      cell: info => info.getValue(),
      footer: info => info.column.id
    }),
    columnHelper.accessor('column2', {
      cell: info => info.getValue(),
      footer: info => info.column.id
    }),
    columnHelper.accessor('column3', {
      cell: info => info.getValue(),
      footer: info => info.column.id
    }),
    columnHelper.accessor('column4', {
      cell: info => info.getValue(),
      footer: info => info.column.id
    }),
    columnHelper.accessor('column5', {
      cell: info => info.getValue(),
      footer: info => info.column.id
    }),
    columnHelper.accessor('column6', {
      cell: info => info.getValue(),
      footer: info => info.column.id
    })
  ];
  const data = [
    {
      column1: 'Column 1',
      column2: 'Column 2',
      column3: 'Column 3',
      column4: 'Column 4',
      column5: 'Column 5',
      column6: 'Column 6'
    },
    {
      column1: 'Column 1',
      column2: 'Column 2',
      column3: 'Column 3',
      column4: 'Column 4',
      column5: 'Column 5',
      column6: 'Column 6'
    },
    {
      column1: 'Lorem Ipsum is simply dummy text ',
      column2: 'Column 2',
      column3: 'Column 3',
      column4: 'Column 4',
      column5: 'Column 5',
      column6: 'Column 6'
    },
    {
      column1: 'Column 1',
      column2: 'Column 2',
      column3: 'Column 3',
      column4: 'Column 4',
      column5: 'Column 5',
      column6: 'Column 6'
    },
    {
      column1: 'Column 1',
      column2: 'Column 2',
      column3: 'Column 3',
      column4: 'Column 4',
      column5: 'Column 5',
      column6: 'Column 6'
    }
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel()
  });

  return (
    <TableWrapper height={height} maxHeight={maxHeight}>
      <Table {...otherProps} equalColumns={equalColumns}>
        {caption && <TableCaption captionSide={captionSide}>{caption}</TableCaption>}
        <TableHead>
          {table.getHeaderGroups().map((headerGroup, index) => (
            <TableHeadRow key={\`header-group-\${index}\`} fixedHeader={fixedHeader}>
              {headerGroup.headers.map(header => (
                <TableHeading key={header.id} fixedColumn={fixedColumn}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </TableHeading>
              ))}
            </TableHeadRow>
          ))}
        </TableHead>

        <TableBody stripedRows={stripedRows} rowHover={rowHover}>
          {table.getRowModel().rows.map(row => (
            <TableRow key={row.id} rowHeight={rowHeight}>
              {row.getVisibleCells().map(cell => (
                <TableData key={cell.id} fixedColumn={fixedColumn}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableData>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};
`}</code></pre>
    <h3 {...{
      "id": "sorting",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#sorting",
        "aria-label": "sorting permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Sorting`}</h3>
    <p>{`Table, in conjunction with `}<inlineCode parentName="p">{`react-table`}</inlineCode>{`, supports sorting data by a single column. Columns can be sorted in ascending or descending order, or reverted to its unsorted state, by clicking on the the column header. The below code snippet provides an example usage of sorting functionality.`}</p>
    <p>{`For more examples and detailed documentation:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://tanstack.com/table/v8/docs/api/features/sorting"
        }}>{`React Table Sorting API Docs`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://tanstack.com/table/v8/docs/examples/react/sorting"
        }}>{`React Table Sorting Examples`}</a></li>
      <li parentName="ul">
        <Link href="/storybook/?path=/story/components-table--sorting" target="_blank" mdxType="Link">Sorting example in storybook</Link>
      </li>
    </ul>
    <SortingTable mdxType="SortingTable" />
    {/* prettier-ignore */}
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React from 'react';
import {ColumnDef, flexRender, getCoreRowModel, getSortedRowModel, SortingState, useReactTable} from '@tanstack/react-table';
import {TableWrapper, Table, TableHead, TableHeadRow, TableHeading, TableBody, TableRow, TableData} from '@nib/table';

type Person = {
  firstName: string;
  lastName: string;
  age: number;
  visits: number;
  progress: number;
  subRows?: Person[];
};

const data = [
  {
    firstName: 'Kaley',
    lastName: 'Pollich',
    age: 12,
    visits: 563,
    progress: 58
  },
  {
    firstName: 'Lurline',
    lastName: 'Kerluke',
    age: 20,
    visits: 877,
    progress: 14
  }
];

export const SortingTable = () => {
  const [sorting, setSorting] = React.useState < SortingState > [];
  const columns = React.useMemo<ColumnDef<Person>[]>(
    () => [
      {
        accessorKey: 'firstName',
        cell: info => info.getValue(),
        footer: props => props.column.id,
        header: () => <span>First Name</span>
      },
      {
        accessorFn: row => row.lastName,
        id: 'lastName',
        cell: info => info.getValue(),
        header: () => <span>Last Name</span>,
        footer: props => props.column.id
      },
      {
        accessorKey: 'age',
        header: () => 'Age',
        footer: props => props.column.id
      },
      {
        accessorKey: 'visits',
        header: () => <span>Visits</span>,
        footer: props => props.column.id
      },
      {
        accessorKey: 'progress',
        header: 'Profile Progress',
        footer: props => props.column.id
      }
    ],
    []
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true
  });

  return (
    <TableWrapper>
      <Table>
        <TableHead>
          {table.getHeaderGroups().map(headerGroup => (
            <TableHeadRow key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <TableHeading
                    key={header.id}
                    colSpan={header.colSpan}
                    canColumnSort={header.column.getCanSort()}
                    onClick={header.column.getToggleSortingHandler()}
                    isSorted={header.column.getIsSorted()}
                  >
                    {header.isPlaceholder ? null : <span>{flexRender(header.column.columnDef.header, header.getContext())}</span>}
                  </TableHeading>
                );
              })}
            </TableHeadRow>
          ))}
        </TableHead>
        <TableBody>
          {table
            .getRowModel()
            .rows.slice(0, 10)
            .map(row => {
              return (
                <TableRow key={row.id}>
                  {row.getVisibleCells().map(cell => {
                    return <TableData key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableData>;
                  })}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};
`}</code></pre>
    <h3 {...{
      "id": "row-selection",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#row-selection",
        "aria-label": "row selection permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Row Selection`}</h3>
    <p>{`To add row selection to your DataTable, you need to add both a `}<inlineCode parentName="p">{`useState`}</inlineCode>{` hook to enable selectable rows and a `}<inlineCode parentName="p">{`onRowSelectionChange`}</inlineCode>{` property to the `}<inlineCode parentName="p">{`useReactTable`}</inlineCode>{` hook.`}</p>
    <p>{`For more examples and detailed documentation:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://tanstack.com/table/v8/docs/api/features/row-selection"
        }}>{`React Table Row Selection API Docs`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://tanstack.com/table/v8/docs/examples/react/row-selection"
        }}>{`React Table Row Selection Examples`}</a></li>
      <li parentName="ul">
        <Link href="/storybook/?path=/story/components-table--row-selection" target="_blank" mdxType="Link">Row Selection example in storybook</Link>
      </li>
    </ul>
    <RowSelectionTable mdxType="RowSelectionTable" />
    {/* prettier-ignore */}
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React from 'react';
import {ColumnDef, flexRender, getCoreRowModel, useReactTable} from '@tanstack/react-table';
import {TableWrapper, Table, TableHead, TableHeadRow, TableHeading, TableBody, TableRow, TableData, IndeterminateCheckbox} from '@nib/table';

type Person = {
  firstName: string;
  lastName: string;
  age: number;
  visits: number;
  progress: number;
  subRows?: Person[];
};

const data = [
  {
    firstName: 'Kaley',
    lastName: 'Pollich',
    age: 12,
    visits: 563,
    progress: 58
  },
  {
    firstName: 'Lurline',
    lastName: 'Kerluke',
    age: 20,
    visits: 877,
    progress: 14
  }
];

export const RowSelectionTable = () => {
  const [rowSelection, setRowSelection] = React.useState({});
  const tableId = React.useId();
  const columns = React.useMemo<ColumnDef<Person>[]>(
    () => [
      {
        id: 'select',
        header: ({table}) => (
          <IndeterminateCheckbox
            {...{
              id: tableId
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler()
            }}
          />
        ),
        cell: ({row}) => (
          <div>
            <IndeterminateCheckbox
              {...{
                id: \`row-\${row.id}-\${tableId}\`,
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler()
              }}
            />
          </div>
        )
      },
      {
        accessorKey: 'firstName',
        cell: info => info.getValue(),
        footer: props => props.column.id,
        header: () => <span>First Name</span>,
        enableColumnFilter: false
      },
      {
        accessorFn: row => row.lastName,
        enableColumnFilter: false,
        id: 'lastName',
        cell: info => info.getValue(),
        header: () => <span>Last Name</span>,
        footer: props => props.column.id
      },

      {
        accessorKey: 'age',
        enableColumnFilter: false,
        header: () => 'Age',
        footer: props => props.column.id
      },
      {
        accessorKey: 'visits',
        enableColumnFilter: false,
        header: () => <span>Visits</span>,
        footer: props => props.column.id
      },
      {
        accessorKey: 'status',
        enableColumnFilter: false,
        header: 'Status',
        footer: props => props.column.id
      },
      {
        accessorKey: 'progress',
        enableColumnFilter: false,
        header: 'Profile Progress',
        footer: props => props.column.id
      }
    ],
    []
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      rowSelection
    },
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true
  });

  return (
    <div>
      <TableWrapper>
        <Table>
          <TableHead>
            {table.getHeaderGroups().map(headerGroup => (
              <TableHeadRow key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <TableHeading key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHeading>
                  );
                })}
              </TableHeadRow>
            ))}
          </TableHead>
          <TableBody>
            {table.getRowModel().rows.map(row => {
              return (
                <TableRow key={row.id}>
                  {row.getVisibleCells().map(cell => {
                    return <TableData key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableData>;
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableWrapper>
      <div>
        {Object.keys(rowSelection).length} of {table.getPreFilteredRowModel().rows.length} Total Rows Selected
      </div>
    </div>
  );
};
`}</code></pre>
    <h3 {...{
      "id": "pagination",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#pagination",
        "aria-label": "pagination permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Pagination`}</h3>
    <p>{`For pagination, we export a standard `}<inlineCode parentName="p">{`Pagination`}</inlineCode>{` component with props compatible with `}<inlineCode parentName="p">{`react-table`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`() => {
  const [pageIndex, setPageIndex] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  return (
    <Pagination
      canPreviousPage={pageIndex > 0}
      canNextPage={pageIndex < 4}
      pageOptions={[10, 20, 30, 40, 50]}
      pageSizeOptions={[10, 20, 30, 40, 50]}
      pageCount={5}
      gotoPage={setPageIndex}
      nextPage={() => setPageIndex(pageIndex + 1)}
      previousPage={() => setPageIndex(pageIndex - 1)}
      setPageSize={setPageSize}
      pageIndex={pageIndex}
      pageSize={pageSize}
    />
  );
};
`}</code></pre>
    <p>{`In the above demo we have used a local state to manage the page information, but in a real table you would use `}<inlineCode parentName="p">{`getPaginationRowModel`}</inlineCode>{` in the `}<inlineCode parentName="p">{`useReactTable`}</inlineCode>{` hook - demonstrated below.`}</p>
    <p>{`For more examples and detailed documentation:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://tanstack.com/table/v8/docs/api/features/pagination"
        }}>{`React Table Pagination API Docs`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://tanstack.com/table/v8/docs/examples/react/pagination"
        }}>{`React Table Pagination Examples`}</a></li>
      <li parentName="ul">
        <Link href="/storybook/?path=/story/components-table--pagination" target="_blank" mdxType="Link">Pagination example in storybook</Link>
      </li>
    </ul>
    <PaginationTable mdxType="PaginationTable" />
    {/* prettier-ignore */}
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React from 'react';
import {ColumnDef, flexRender, getCoreRowModel, useReactTable, getPaginationRowModel} from '@tanstack/react-table';
import {TableWrapper, Table, TableHead, TableHeadRow, TableHeading, TableBody, TableRow, TableData, Pagination} from '@nib/table';

type Person = {
  firstName: string;
  lastName: string;
  age: number;
  visits: number;
  progress: number;
  subRows?: Person[];
};

const data = [
  {
    firstName: 'Kaley',
    lastName: 'Pollich',
    age: 12,
    visits: 563,
    progress: 58
  },
  {
    firstName: 'Lurline',
    lastName: 'Kerluke',
    age: 20,
    visits: 877,
    progress: 14,
    status: 'complicated'
  },
  {
    firstName: 'Raquel',
    lastName: 'Kuhic',
    age: 15,
    visits: 939,
    progress: 91
  },
  {
    firstName: 'Vivienne',
    lastName: 'Schuppe',
    age: 34,
    visits: 807,
    progress: 49,
    status: 'relationship'
  },
  {
    firstName: 'Isabella',
    lastName: 'Feeney',
    age: 10,
    visits: 979,
    progress: 31,
    status: 'relationship'
  },
  {
    firstName: 'Harold',
    lastName: 'Keebler',
    age: 33,
    visits: 500,
    progress: 93,
    status: 'complicated'
  },
  {
    firstName: 'Kali',
    lastName: 'McClure',
    age: 17,
    visits: 164,
    progress: 77,
    status: 'complicated'
  },
  {
    firstName: 'Nichole',
    lastName: 'Daugherty',
    age: 40,
    visits: 495,
    progress: 82,
    status: 'complicated'
  },
  {
    firstName: 'Dixie',
    lastName: 'Bayer',
    age: 14,
    visits: 478,
    progress: 34,
    status: 'complicated'
  },
  {
    firstName: 'Bruce',
    lastName: 'Macejkovic',
    age: 34,
    visits: 43,
    progress: 99,
    status: 'relationship'
  },
  {
    firstName: 'Pietro',
    lastName: 'Koss',
    age: 6,
    visits: 624,
    progress: 60
  },
  {
    firstName: 'Karen',
    lastName: 'Torphy',
    age: 18,
    visits: 21,
    progress: 51
  },
  {
    firstName: 'Marco',
    lastName: 'Abernathy',
    age: 11,
    visits: 325,
    progress: 80,
    status: 'complicated'
  },
  {
    firstName: 'Lelah',
    lastName: 'Bradtke',
    age: 16,
    visits: 589,
    progress: 38,
    status: 'complicated'
  },
  {
    firstName: 'Meda',
    lastName: 'Thiel',
    age: 22,
    visits: 767,
    progress: 58,
    status: 'relationship'
  },
  {
    firstName: 'Elda',
    lastName: 'Berge',
    age: 7,
    visits: 639,
    progress: 3,
    status: 'complicated'
  },
  {
    firstName: 'Lucy',
    lastName: 'Block',
    age: 30,
    visits: 970,
    progress: 98,
    status: 'relationship'
  },
  {
    firstName: 'Adan',
    lastName: 'Mante',
    age: 12,
    visits: 326,
    progress: 77
  },
  {
    firstName: 'Patricia',
    lastName: 'Friesen',
    age: 11,
    visits: 954,
    progress: 75,
    status: 'relationship'
  },
  {
    firstName: 'Katheryn',
    lastName: 'Walter',
    age: 11,
    visits: 558,
    progress: 33,
    status: 'relationship'
  },
  {
    firstName: 'Adelle',
    lastName: 'Armstrong',
    age: 13,
    visits: 560,
    progress: 47,
    status: 'relationship'
  },
  {
    firstName: 'Mose',
    lastName: 'Rowe',
    age: 18,
    visits: 198,
    progress: 42,
    status: 'relationship'
  },
  {
    firstName: 'Itzel',
    lastName: 'Fritsch',
    age: 17,
    visits: 193,
    progress: 28,
    status: 'complicated'
  },
  {
    firstName: 'Reinhold',
    lastName: 'Wiza',
    age: 17,
    visits: 390,
    progress: 67,
    status: 'relationship'
  },
  {
    firstName: 'Giuseppe',
    lastName: 'Turner',
    age: 14,
    visits: 111,
    progress: 59
  },
  {
    firstName: 'Annalise',
    lastName: 'Barrows',
    age: 3,
    visits: 837,
    progress: 89,
    status: 'relationship'
  },
  {
    firstName: 'Lilliana',
    lastName: 'Donnelly',
    age: 12,
    visits: 755,
    progress: 83,
    status: 'complicated'
  },
  {
    firstName: 'Monique',
    lastName: 'Klein',
    age: 8,
    visits: 70,
    progress: 72
  },
  {
    firstName: 'Leland',
    lastName: 'Halvorson',
    age: 28,
    visits: 388,
    progress: 4
  },
  {
    firstName: 'Theresia',
    lastName: 'Stroman',
    age: 26,
    visits: 614,
    progress: 61,
    status: 'complicated'
  }
];

export const PaginationTable = () => {
  const columns = React.useMemo<ColumnDef<Person>[]>(
    () => [
      {
        accessorKey: 'firstName',
        cell: info => info.getValue(),
        footer: props => props.column.id,
        header: () => <span>First Name</span>
      },
      {
        accessorFn: row => row.lastName,
        id: 'lastName',
        cell: info => info.getValue(),
        header: () => <span>Last Name</span>,
        footer: props => props.column.id
      },
      {
        accessorKey: 'age',
        header: () => 'Age',
        footer: props => props.column.id
      },
      {
        accessorKey: 'visits',
        header: () => <span>Visits</span>,
        footer: props => props.column.id
      },
      {
        accessorKey: 'progress',
        header: 'Profile Progress',
        footer: props => props.column.id
      }
    ],
    []
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true
  });
  return (
    <>
      <TableWrapper>
        <Table>
          <TableHead>
            {table.getHeaderGroups().map(headerGroup => (
              <TableHeadRow key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <TableHeading key={header.id} colSpan={header.colSpan} onClick={header.column.getToggleSortingHandler()} isSorted={header.column.getIsSorted()}>
                      {header.isPlaceholder ? null : <span>{flexRender(header.column.columnDef.header, header.getContext())}</span>}
                    </TableHeading>
                  );
                })}
              </TableHeadRow>
            ))}
          </TableHead>
          <TableBody>
            {table.getRowModel().rows.map(row => {
              return (
                <TableRow key={row.id}>
                  {row.getVisibleCells().map(cell => {
                    return <TableData key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableData>;
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        <Pagination
          canPreviousPage={table.getCanPreviousPage()}
          canNextPage={table.getCanNextPage()}
          pageOptions={table.getPageOptions()}
          pageCount={table.getPageCount()}
          gotoPage={table.setPageIndex}
          nextPage={table.nextPage}
          previousPage={table.previousPage}
          setPageSize={table.setPageSize}
          pageIndex={table.getState().pagination.pageIndex}
          pageSize={table.getState().pagination.pageSize}
        />
      </TableWrapper>
    </>
  );
};
`}</code></pre>
    <h3 {...{
      "id": "filter",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#filter",
        "aria-label": "filter permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Filter`}</h3>
    <p><inlineCode parentName="p">{`react-table`}</inlineCode>{` provides mechanisms for filtering the entire data set passed to the table, not just what is displayed on the current page (particularly where pagination is in use). The below example shows how to hook up this global filtering functionality with Mesh's `}<inlineCode parentName="p">{`Textbox`}</inlineCode>{` (and its `}<inlineCode parentName="p">{`FormControl`}</inlineCode>{` wrapper).`}</p>
    <p>{`You will also likely want to create your own fuzzy filter and debounced input. The below example shows how to set this up using the `}<inlineCode parentName="p">{`@tanstack/match-sorter-utils`}</inlineCode>{` package.`}</p>
    <p>{`For more examples and detailed documentation:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://tanstack.com/table/v8/docs/api/features/filters"
        }}>{`React Table Filtering API Docs`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://tanstack.com/table/v8/docs/examples/react/filters"
        }}>{`React Table Filtering Examples`}</a></li>
      <li parentName="ul">
        <Link href="/storybook/?path=/story/components-table--filter-global" target="_blank" mdxType="Link">Filtering example in storybook</Link>
      </li>
    </ul>
    <FilterTable mdxType="FilterTable" />
    {/* prettier-ignore */}
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React from 'react';
import styled from 'styled-components';
import {ColumnDef, flexRender, getCoreRowModel, useReactTable, getFilteredRowModel, getFacetedRowModel, getFacetedUniqueValues, getFacetedMinMaxValues, FilterFn} from '@tanstack/react-table';
import {rankItem} from '@tanstack/match-sorter-utils';
import {TableWrapper, Table, TableHead, TableHeadRow, TableHeading, TableBody, TableRow, TableData, SearchBox} from '@nib/table';
import {SearchSystemIcon} from '@nib/icons';
import {Box} from '@nib/layout';

type Person = {
  firstName: string;
  lastName: string;
  age: number;
  visits: number;
  progress: number;
  subRows?: Person[];
};

const data = [
  {
    firstName: 'Kaley',
    lastName: 'Pollich',
    age: 12,
    visits: 563,
    progress: 58
  },
  {
    firstName: 'Lurline',
    lastName: 'Kerluke',
    age: 20,
    visits: 877,
    progress: 14,
    status: 'complicated'
  },
  {
    firstName: 'Raquel',
    lastName: 'Kuhic',
    age: 15,
    visits: 939,
    progress: 91
  },
  {
    firstName: 'Vivienne',
    lastName: 'Schuppe',
    age: 34,
    visits: 807,
    progress: 49
  },
  {
    firstName: 'Isabella',
    lastName: 'Feeney',
    age: 10,
    visits: 979,
    progress: 31
  },
  {
    firstName: 'Harold',
    lastName: 'Keebler',
    age: 33,
    visits: 500,
    progress: 93
  },
  {
    firstName: 'Kali',
    lastName: 'McClure',
    age: 17,
    visits: 164,
    progress: 77
  },
  {
    firstName: 'Theresia',
    lastName: 'Stroman',
    age: 26,
    visits: 614,
    progress: 61
  }
];

export const FilterTable = () => {
  const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value);

    // Store the itemRank info
    addMeta({
      itemRank
    });

    // Return if the item should be filtered in/out
    return itemRank.passed;
  };

  const [globalFilter, setGlobalFilter] = React.useState('');

  const columns = React.useMemo<ColumnDef<Person, any>[]>(
    () => [
      {
        accessorKey: 'firstName',
        enableColumnFilter: false,
        cell: info => info.getValue(),
        footer: props => props.column.id,
        header: () => <span>First Name</span>
      },
      {
        accessorFn: row => row.lastName,
        id: 'lastName',
        enableColumnFilter: false,
        cell: info => info.getValue(),
        header: () => <span>Last Name</span>,
        footer: props => props.column.id
      },
      {
        accessorFn: row => \`\${row.firstName} \${row.lastName}\`,
        id: 'fullName',
        header: 'Full Name',
        enableColumnFilter: false,
        cell: info => info.getValue(),
        footer: props => props.column.id
      },

      {
        accessorKey: 'age',
        header: () => 'Age',
        enableColumnFilter: false,
        footer: props => props.column.id
      },

      {
        accessorKey: 'visits',
        enableColumnFilter: false,
        header: () => <span>Visits</span>,
        footer: props => props.column.id
      },
      {
        accessorKey: 'status',
        header: 'Status',
        enableColumnFilter: false,
        footer: props => props.column.id
      },
      {
        accessorKey: 'progress',
        header: 'Profile Progress',
        enableColumnFilter: false,
        footer: props => props.column.id
      }
    ],
    []
  );

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter
    },
    state: {
      globalFilter
    },
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false
  });

  return (
    <div>
      <div>
        <DebouncedInput value={globalFilter ?? ''} onChange={value => setGlobalFilter(String(value))} placeholder="Search all columns..." />
      </div>
      <TableWrapper>
        <Table>
          <TableHead>
            {table.getHeaderGroups().map(headerGroup => (
              <TableHeadRow key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <TableHeading key={header.id} colSpan={header.colSpan} onClick={header.column.getToggleSortingHandler()} isSorted={header.column.getIsSorted()}>
                      {header.isPlaceholder ? null : <span>{flexRender(header.column.columnDef.header, header.getContext())}</span>}
                    </TableHeading>
                  );
                })}
              </TableHeadRow>
            ))}
          </TableHead>
          <TableBody stripedRows>
            {table.getRowModel().rows.map(row => {
              return (
                <TableRow key={row.id}>
                  {row.getVisibleCells().map(cell => {
                    return <TableData key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableData>;
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableWrapper>
    </div>
  );
};

// Anm example debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value, onChange, debounce]);

  return (
    <SearchBox value={value || ''} onChange={(e: any) => setValue(e.target.value)} placeholder="Filter table" {...props}/>
  );
}
`}</code></pre>
    <h3 {...{
      "id": "expanding-row",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#expanding-row",
        "aria-label": "expanding row permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Expanding Row`}</h3>
    <p>{`Expanding rows can be used to reveal additional information or actions about a table row. At present, a chevron at the end of the row functions as the only clickable trigger.`}</p>
    <p>{`For more examples and detailed documentation:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://tanstack.com/table/v8/docs/api/features/filters"
        }}>{`React Table Expanding API Docs`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://tanstack.com/table/v8/docs/examples/react/filters"
        }}>{`React Table Expanding Examples`}</a></li>
      <li parentName="ul">
        <Link href="/storybook/?path=/story/components-table--expanding" target="_blank" mdxType="Link">Expanding example in storybook</Link>
      </li>
    </ul>
    <ExpandingRowTable mdxType="ExpandingRowTable" />
    {/* prettier-ignore */}
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React from 'react';
import {ColumnDef, flexRender, getCoreRowModel, getSortedRowModel, SortingState, useReactTable, getPaginationRowModel, getExpandedRowModel, ExpandedState, Row} from '@tanstack/react-table';
import {TableWrapper, Table, TableHead, TableHeadRow, TableHeading, TableBody, TableExpandRow, TableData, TableExpandHeading} from '@nib/table';

type Person = {
  firstName: string;
  lastName: string;
  age: number;
  visits: number;
  progress: number;
  subRows?: Person[];
};

const data = [
  {
    firstName: 'Kaley',
    lastName: 'Pollich',
    age: 12,
    visits: 563,
    progress: 58
  },
  {
    firstName: 'Lurline',
    lastName: 'Kerluke',
    age: 20,
    visits: 877,
    progress: 14,
    status: 'complicated'
  },
  {
    firstName: 'Raquel',
    lastName: 'Kuhic',
    age: 15,
    visits: 939,
    progress: 91
  },
  {
    firstName: 'Vivienne',
    lastName: 'Schuppe',
    age: 34,
    visits: 807,
    progress: 49,
    status: 'relationship'
  },
  {
    firstName: 'Isabella',
    lastName: 'Feeney',
    age: 10,
    visits: 979,
    progress: 31,
    status: 'relationship'
  },
  {
    firstName: 'Harold',
    lastName: 'Keebler',
    age: 33,
    visits: 500,
    progress: 93,
    status: 'complicated'
  },
  {
    firstName: 'Kali',
    lastName: 'McClure',
    age: 17,
    visits: 164,
    progress: 77,
    status: 'complicated'
  },
  {
    firstName: 'Theresia',
    lastName: 'Stroman',
    age: 26,
    visits: 614,
    progress: 61,
    status: 'complicated'
  }
];

export const ExpandingRowTable = () => {
  const columns = React.useMemo<ColumnDef<Person>[]>(
    () => [
      {
        accessorKey: 'firstName',
        header: () => <>First Name</>,
        cell: ({getValue}) => getValue(),
        footer: props => props.column.id
      },
      {
        accessorFn: row => row.lastName,
        id: 'lastName',
        cell: info => info.getValue(),
        header: () => <span>Last Name</span>,
        footer: props => props.column.id
      },

      {
        accessorKey: 'age',
        header: () => 'Age',
        footer: props => props.column.id
      },
      {
        accessorKey: 'visits',
        header: () => <span>Visits</span>,
        footer: props => props.column.id
      },
      {
        accessorKey: 'progress',
        header: 'Profile Progress',
        footer: props => props.column.id
      }
    ],
    []
  );

  const [expanded, setExpanded] = React.useState < ExpandedState > {};

  const table = useReactTable({
    data,
    columns,
    state: {
      expanded
    },
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getRowCanExpand: () => true,
    debugTable: true
  });

  return (
    <>
      <TableWrapper>
        <Table>
          <TableHead>
            {table.getHeaderGroups().map(headerGroup => (
              <TableHeadRow key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <TableHeading key={header.id} colSpan={header.colSpan} onClick={header.column.getToggleSortingHandler()} isSorted={header.column.getIsSorted()}>
                      {header.isPlaceholder ? null : <span>{flexRender(header.column.columnDef.header, header.getContext())}</span>}
                    </TableHeading>
                  );
                })}
                <TableExpandHeading /> {/* Empty th for expander column */}
              </TableHeadRow>
            ))}
          </TableHead>
          <TableBody stripedRows>
            {table.getRowModel().rows.map(row => {
              return (
                <>
                  <TableExpandRow key={row.id} isExpanded={row.getIsExpanded()} onClick={row.getToggleExpandedHandler()}>
                    {row.getVisibleCells().map(cell => {
                      return <TableData key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableData>;
                    })}
                  </TableExpandRow>
                  {row.getIsExpanded() && (
                    <tr>
                      {/* 2nd row is a custom 1 cell row (+1 to cater for empty TableExpandHeading) */}
                      <td colSpan={row.getVisibleCells().length + 1}>{renderSubComponent({row})}</td>
                    </tr>
                  )}
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableWrapper>
    </>
  );
};

const renderSubComponent = ({row}: {row: Row<Person>}) => {
  return <p>Render extra details here about {row.original.firstName}</p>;
};
`}</code></pre>
    <h2 {...{
      "id": "props",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#props",
        "aria-label": "props permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Props`}</h2>
    <p>{`As we have built this package on `}<a parentName="p" {...{
        "href": "https://react-table.tanstack.com/"
      }}>{`react-table`}</a>{`, we rely on their implementation of the `}<inlineCode parentName="p">{`columns`}</inlineCode>{` and `}<inlineCode parentName="p">{`data`}</inlineCode>{` props to populate the table.`}</p>
    <p>{`Also, as the package adopts the "headless" approach and you must provide the markup for your table, the below props must be set on the appropriate table components. The `}<strong parentName="p">{`Target`}</strong>{` Column indicates which component(s) accept the prop.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Target`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`rowHeight`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'regular'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`TableHeadRow`}</inlineCode>{`, `}<inlineCode parentName="td">{`TableRow`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The height of the table rows. Must be one of `}<inlineCode parentName="td">{`'relaxed'`}</inlineCode>{`, `}<inlineCode parentName="td">{`'regular'`}</inlineCode>{`, `}<inlineCode parentName="td">{`'condensed'`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`stripedRows`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`TableBody`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Applies alternate colours to the table rows for improved scanability.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`equalColumns`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`Table`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sets an equal width to all columns.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`fixedHeader`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`TableHeadRow`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Fixes the header to the top of the table when vertically scrolling.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`fixedColumn`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`TableHeading`}</inlineCode>{`, `}<inlineCode parentName="td">{`TableData`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Fixes the first column to the left of the table when horizontally scrolling.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`rowHover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`TableBody`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Enables a hover state when the cursor moves over a row. This may assist a user in scanning rows, but can imply interactivity where there is none.`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "considerations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#considerations",
        "aria-label": "considerations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Considerations`}</h2>
    <h3 {...{
      "id": "columns",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#columns",
        "aria-label": "columns permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Columns`}</h3>
    <p>{`Columns plays a key role in defining the datatable. Most of the functionality depends on the column defs types and Column Helpers.`}</p>
    <p>{`For further reading and documentation:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://tanstack.com/table/v8/docs/api/core/column"
        }}>{`https://tanstack.com/table/v8/docs/api/core/column`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://tanstack.com/table/v8/docs/api/core/column-def"
        }}>{`https://tanstack.com/table/v8/docs/api/core/column-def`}</a></li>
    </ul>
    <h3 {...{
      "id": "ensure-content-is-organised-and-intuitive",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#ensure-content-is-organised-and-intuitive",
        "aria-label": "ensure content is organised and intuitive permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Ensure content is organised and intuitive`}</h3>
    <p>{`Structured tables should organize content in a meaningful way, such as using hierarchy or alphabetisation, and follow a logical structure that makes content easy to understand.`}</p>
    <h3 {...{
      "id": "consider-typography-and-alignment",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#consider-typography-and-alignment",
        "aria-label": "consider typography and alignment permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Consider typography and alignment`}</h3>
    <p>{`Headers should be set in `}<em parentName="p">{`Title`}</em>{` case, while all other text is set in sentence case. All typography is left-aligned by default. This helps make the data easily scannable, readable and comparable. The one exception is numeric data, which should be right aligned to help users quickly identify larger numbers.`}</p>
    <h3 {...{
      "id": "be-efficient-with-your-content",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#be-efficient-with-your-content",
        "aria-label": "be efficient with your content permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Be efficient with your content`}</h3>
    <p>{`Using concise, scannable and objective language increases usabilty and readability. Consider using only 45 to 75 characters (including spaces and punctuation) per line.`}</p>
    <h3 {...{
      "id": "utilise-visual-cues",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#utilise-visual-cues",
        "aria-label": "utilise visual cues permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Utilise visual cues`}</h3>
    <p>{`Using differently-coloured backgrounds can give organisational context and meaning to your table. Whether for your header or for alternating rows, these visual cues can help present data in a way that is easier to scan and understand.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`stripedRows`}</inlineCode>{` prop can help achieve this and is recommended for larger data sets, where the alternated pattern can improve a users's speed of comprehension when reading along a row.`}</p>
    <h3 {...{
      "id": "consider-column-widths",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#consider-column-widths",
        "aria-label": "consider column widths permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Consider column widths`}</h3>
    <p>{`When presenting data that is similar or comparable between columns, consider using even column widths. This can be achieved by using the `}<inlineCode parentName="p">{`equalColumns`}</inlineCode>{` prop.`}</p>
    <p>{`At times, content might need to be structured to fit disproportionately, allowing for flexibility of headers and corresponding columns to be changed based on content length. The number of characters for readability per line should not go beyond 45 to 75 characters (including spaces and punctuation).`}</p>
    <p>{`In tables that use uneven column widths, ensure that the `}<inlineCode parentName="p">{`collapseColumn`}</inlineCode>{` prop is not applied to the last column in the table.`}</p>
    <h3 {...{
      "id": "use-row-height-props-effectively",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#use-row-height-props-effectively",
        "aria-label": "use row height props effectively permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Use row height props effectively`}</h3>
    <p>{`When choosing row heights, be sure to consider the type and volume of data in your table. `}<inlineCode parentName="p">{`regular`}</inlineCode>{` and `}<inlineCode parentName="p">{`relaxed`}</inlineCode>{` row heights offer more white space, and are more comfortable when working with large datasets. Using a `}<inlineCode parentName="p">{`condensed`}</inlineCode>{` row height will allow the user to view more data at once without having to scroll, but will reduce the table's readability and potentially cause parsing errors for the user.`}</p>
    <p>{`In summary:`}</p>
    <ul>
      <li parentName="ul">{`Use `}<inlineCode parentName="li">{`relaxed`}</inlineCode>{` row heights when you have visually-heavy content or a dataset of less than 25 rows.`}</li>
      <li parentName="ul">{`Use `}<inlineCode parentName="li">{`regular`}</inlineCode>{` row heights (default) when you only have a couple of words per column and need to provide easy scanability between .`}</li>
      <li parentName="ul">{`Use `}<inlineCode parentName="li">{`condensed`}</inlineCode>{` row heights when space is limited or for more numerical datasets.`}</li>
    </ul>
    <h3 {...{
      "id": "maintain-context-while-scrolling",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#maintain-context-while-scrolling",
        "aria-label": "maintain context while scrolling permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Maintain context while scrolling`}</h3>
    <p>{`Anchoring contextual information will help users understand what data they're looking at, particularly when scrolling down or across a table. This functionality is important when designing tables with large datasets or on smaller screens.`}</p>
    <p>{`The two props that will assist in this design are `}<inlineCode parentName="p">{`fixedHeader`}</inlineCode>{` and `}<inlineCode parentName="p">{`fixedColumn`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "consider-responsive-behaviour",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#consider-responsive-behaviour",
        "aria-label": "consider responsive behaviour permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Consider responsive behaviour`}</h3>
    <p>{`Along with maintaining context while scrolling, the number of columns that fit on a mobile screen without scrolling is important to consider. Items need to be legible without requiring the user to zoom in. For complex or wordy entries, such as those in comparison tables, only 2 columns may fit legibly on a narrow mobile screen. For a number-heavy table, narrower columns may work, allowing more columns to be visible.`}</p>
    <p>{`To make certain column widths smaller, consider using the `}<inlineCode parentName="p">{`collapse`}</inlineCode>{` option in the `}<inlineCode parentName="p">{`columns`}</inlineCode>{` prop array.`}</p>
    <h2 {...{
      "id": "more-information",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#more-information",
        "aria-label": "more information permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`More information`}</h2>
    <p>{`Below links provide a complete guidance and how to use the functionalities which were not listed in the above section.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://tanstack.com/table/v8/docs/guide/introduction"
        }}>{`https://tanstack.com/table/v8/docs/guide/introduction`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://tanstack.com/table/v8/docs/guide/overview"
        }}>{`https://tanstack.com/table/v8/docs/guide/overview`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://tanstack.com/table/v8/docs/examples/react/basic"
        }}>{`https://tanstack.com/table/v8/docs/examples/react/basic`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      